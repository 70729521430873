// Generated by Framer (dbba614)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ka44FrJww", "LMioE4id2"];

const serializationHash = "framer-cM6FJ"

const variantClassNames = {ka44FrJww: "framer-v-124xku2", LMioE4id2: "framer-v-x8bipl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ka44FrJww", "Variant 2": "LMioE4id2"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ka44FrJww"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ka44FrJww", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-124xku2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ka44FrJww"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({LMioE4id2: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><SVG className={"framer-z2q19p"} data-framer-name={"Ic-menu"} layout={"position"} layoutDependency={layoutDependency} layoutId={"lPl9K9uqc"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 2.5 5.832 L 17.5 5.832 M 2.5 14.165 L 17.5 14.165\" fill=\"transparent\" stroke-width=\"2.2\" stroke=\"rgb(20,20,31)\" stroke-linecap=\"square\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>"} svgContentId={11181781594} withExternalLayout {...addPropertyOverrides({LMioE4id2: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 5 5 L 15 15 M 5 15 L 15 5\" fill=\"transparent\" stroke-width=\"2.2\" stroke=\"var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)) /* {&quot;name&quot;:&quot;primary-black&quot;} */\" stroke-linecap=\"square\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>", svgContentId: 11612510776}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cM6FJ.framer-aqm9kd, .framer-cM6FJ .framer-aqm9kd { display: block; }", ".framer-cM6FJ.framer-124xku2 { height: 20px; overflow: visible; position: relative; width: 20px; }", ".framer-cM6FJ .framer-z2q19p { flex: none; height: 20px; left: 0px; position: absolute; top: 0px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LMioE4id2":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpvGr1s8Yx: React.ComponentType<Props> = withCSS(Component, css, "framer-cM6FJ") as typeof Component;
export default FramerpvGr1s8Yx;

FramerpvGr1s8Yx.displayName = "ic-menu-20px";

FramerpvGr1s8Yx.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerpvGr1s8Yx, {variant: {options: ["ka44FrJww", "LMioE4id2"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerpvGr1s8Yx, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})